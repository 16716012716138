import certified from './assets/images_cb/image2.jpg';
import coffeeBeans from './assets/images_cb/images4.jpg';
import coffee from './assets/images_cb/images3.webp';
import freeDelivery from './assets/images_cb/images1.jpg';
import images5 from './assets/images_cb/images5.webp';

import menu1 from './assets/01.jpg';
import menu2 from './assets/02.jpg';
import menu3 from './assets/03.jpg';
import menu4 from './assets/04.jpg';
import menu5 from './assets/05.jpg';
import menu6 from './assets/06.jpg';
import menu7 from './assets/07.jpg';
import menu8 from './assets/08.jpg';

import gallery1 from './assets/gallery-1.jpg';
import gallery2 from './assets/gallery-2.jpg';
import gallery3 from './assets/gallery-3.jpg';
import gallery4 from './assets/gallery-4.jpg';
import gallery5 from './assets/gallery-5.jpg';

import offer1 from './assets/offer-1.jpg';
import offer2 from './assets/offer-2.jpg';

import testimonial1 from './assets/testimonial-1.jpg';
import testimonial2 from './assets/testimonial-2.jpg';
import testimonial3 from './assets/testimonial-3.jpg';
import testimonial4 from './assets/testimonial-4.jpg';
import rooms11 from './assets/rooms/rooms11.jpg';
import rooms12 from './assets/rooms/rooms12.jpg';
import rooms13 from './assets/rooms/rooms13.jpg';
import rooms14 from './assets/rooms/rooms14.jpg';
import rooms15 from './assets/rooms/rooms15.jpg';
import rooms16 from './assets/rooms/rooms16.jpg';
import rooms17 from './assets/rooms/rooms17.jpg';
import rooms18 from './assets/rooms/rooms18.jpg';
import rooms19 from './assets/rooms/rooms19.jpg';
import rooms110 from './assets/rooms/rooms110.jpg';
import rooms111 from './assets/rooms/rooms111.jpg';
import rooms112 from './assets/rooms/rooms112.jpg';
import rooms113 from './assets/rooms/rooms113.jpg';
import rooms114 from './assets/rooms/rooms114.jpg';

import rooms21 from './assets/rooms/rooms21.jpg';
import rooms22 from './assets/rooms/rooms22.jpg';
import rooms23 from './assets/rooms/rooms23.jpg';
import rooms24 from './assets/rooms/rooms24.jpg';
import rooms25 from './assets/rooms/rooms25.jpg';
import rooms26 from './assets/rooms/rooms26.jpg';
import rooms27 from './assets/rooms/rooms27.jpg';
import rooms28 from './assets/rooms/rooms28.jpg';
import rooms29 from './assets/rooms/rooms29.jpg';
import rooms210 from './assets/rooms/rooms210.jpg';
import rooms211 from './assets/rooms/rooms211.jpg';
import rooms212 from './assets/rooms/rooms212.jpg';
import rooms213 from './assets/rooms/rooms213.jpg';
import rooms214 from './assets/rooms/rooms214.jpg';
import rooms215 from './assets/rooms/rooms215.jpg';
import rooms216 from './assets/rooms/rooms216.jpg';
import rooms217 from './assets/rooms/rooms217.jpg';
import rooms218 from './assets/rooms/rooms218.jpg';

import rooms31 from './assets/rooms/rooms31.jpg';
import rooms32 from './assets/rooms/rooms32.jpg';
import rooms33 from './assets/rooms/rooms33.jpg';
import rooms34 from './assets/rooms/rooms34.jpg';
import rooms35 from './assets/rooms/rooms35.jpg';
import rooms36 from './assets/rooms/rooms36.jpg';
import rooms37 from './assets/rooms/rooms37.jpg';
import rooms38 from './assets/rooms/rooms38.jpg';
import rooms39 from './assets/rooms/rooms39.jpg';
import rooms310 from './assets/rooms/rooms310.jpg';

import rooms41 from './assets/rooms/rooms41.jpg';
import rooms42 from './assets/rooms/rooms42.jpg';
import rooms43 from './assets/rooms/rooms43.jpg';
import rooms44 from './assets/rooms/rooms44.jpg';
import rooms45 from './assets/rooms/rooms45.jpg';
import rooms46 from './assets/rooms/rooms46.jpg';
import rooms47 from './assets/rooms/rooms47.jpg';
import rooms48 from './assets/rooms/rooms48.jpg';
import rooms49 from './assets/rooms/rooms49.jpg';

import rooms51 from './assets/rooms/rooms51.jpg';
import rooms52 from './assets/rooms/rooms52.jpg';
import rooms53 from './assets/rooms/rooms53.jpg';
import rooms54 from './assets/rooms/rooms54.jpg';
import rooms55 from './assets/rooms/rooms55.jpg';
import rooms56 from './assets/rooms/rooms56.jpg';
import rooms57 from './assets/rooms/rooms57.jpg';
import rooms58 from './assets/rooms/rooms58.jpg';
import rooms59 from './assets/rooms/rooms59.jpg';
import rooms510 from './assets/rooms/rooms510.jpg';
import rooms511 from './assets/rooms/rooms511.jpg';
import rooms512 from './assets/rooms/rooms512.jpg';
import rooms513 from './assets/rooms/rooms513.jpg';
export const links = [
    {
        name: 'TRANG CHỦ',
        path: 'home',
    },
    {
        name: 'CAFE - MENU',
        path: 'coffe',
    },
    {
        name: 'HOME STAY',
        path: 'homestay',
    },
    {
        name: 'SẢN PHẨM',
        path: '',
    },
    {
        name: 'TIN TỨC',
        path: 'news',
    },
    {
        name: 'LIÊN HỆ',
        path: 'contact',
    }
];

export const features = [
    {
        id: 1,
        img: freeDelivery,
        link: 'https://dulichvietnam.com.vn/ca-phe-cuc-bac-ha-giang.html',
        description: 'Cà phê Cực Bắc Hà Giang - quán cà phê hút khách ở Lô Lô Chải'
    },
    {
        id: 2,
        img: certified,
        link: 'https://vnexpress.net/len-ha-giang-uong-ca-phe-cuc-bac-4194198.html',
        description:
            'Lên Hà Giang uống cà phê Cực Bắc'
    },
    {
        id: 3,
        img: coffee,
        link: 'https://dantri.com.vn/van-hoa/thuong-thuc-ca-phe-o-cuc-bac-to-quoc-20201207200752390.htm',
        description:
            'Thưởng thức cà phê ở cực Bắc Tổ quốc'
    },
    {
        id: 4,
        img: coffeeBeans,
        link: 'https://dulich.laodong.vn/tin-tuc/nguoi-dan-ong-nhat-yeu-ha-giang-moi-thang-danh-2-tuan-o-lo-lo-chai-1147929.html?gidzl=GExVH5EyvnP5-DjBLQNZ44R-sKXAfk4R2gZSH1Zmune9hzT3JlRYJGgjt18PzU9CNgcC5cOYQWrgKBNX6m',
        description:
            'Người đàn ông Nhật yêu Hà Giang, mỗi tháng dành 2 tuần ở Lô Lô Chải'
    },
    {
        id: 5,
        img: images5,
        link: 'https://trangtraiviet.danviet.vn/mot-lan-tham-ngoi-nha-co-gan-200-tuoi-cua-nguoi-lo-lo-o-ha-giang-20230302171818368.htm',
        description:
            'Một lần thăm ngôi nhà cổ gần 200 tuổi của người Lô Lô ở Hà Giang'
    },
    {
        id: 6,
        img: freeDelivery,
        link: 'https://dulichvietnam.com.vn/ca-phe-cuc-bac-ha-giang.html',
        description: 'Cà phê Cực Bắc Hà Giang - quán cà phê hút khách ở Lô Lô Chải'
    },
    {
        id: 7,
        img: certified,
        link: 'https://vnexpress.net/len-ha-giang-uong-ca-phe-cuc-bac-4194198.html',
        description:
            'Lên Hà Giang uống cà phê Cực Bắc'
    },
    {
        id: 8,
        img: coffee,
        link: 'https://dantri.com.vn/van-hoa/thuong-thuc-ca-phe-o-cuc-bac-to-quoc-20201207200752390.htm',
        description:
            'Thưởng thức cà phê ở cực Bắc Tổ quốc'
    },
    {
        id: 9,
        img: coffeeBeans,
        link: 'https://dulich.laodong.vn/tin-tuc/nguoi-dan-ong-nhat-yeu-ha-giang-moi-thang-danh-2-tuan-o-lo-lo-chai-1147929.html?gidzl=GExVH5EyvnP5-DjBLQNZ44R-sKXAfk4R2gZSH1Zmune9hzT3JlRYJGgjt18PzU9CNgcC5cOYQWrgKBNX6m',
        description:
            'Người đàn ông Nhật yêu Hà Giang, mỗi tháng dành 2 tuần ở Lô Lô Chải'
    },
    {
        id: 10,
        img: images5,
        link: 'https://trangtraiviet.danviet.vn/mot-lan-tham-ngoi-nha-co-gan-200-tuoi-cua-nguoi-lo-lo-o-ha-giang-20230302171818368.htm',
        description:
            'Một lần thăm ngôi nhà cổ gần 200 tuổi của người Lô Lô ở Hà Giang'
    }
];
export const features1 = [
    {
        id: 1,
        title: "Nơi lưu giữ trọn vẹn nét văn hóa truyền thống",
        description: 'Ngôi nhà có niên đại hơn 200 năm tuổi với kiến trúc cổ kính, mang đậm nét văn hóa của người dân tộc Lô Lô sẽ cho bạn cảm giác bình yên, thanh thản khi dừng chân tại đây Cafe Cực Bắc không chỉ là một quán cafe mà còn có Homestay giúp bạn có những phút giây hòa mình vào cuộc sống bình dị của đồng bào Lô Lô'
    },
    {
        id: 2,
        title: 'Gìn giữ trọn vẹn giá trị văn hóa trong từng sản phẩm',
        description:
            'Mong muốn mang đến các sản phẩm đặc sản địa phương với chất lượng tốt, chúng tôi hướng đến việc ai cũng có thể thưởng thức các sản phẩm văn hóa địa phương - Cao nguyên đá Đồng Văn, mang đến sự an tâm tuyệt đối trong từng sản phẩm.'
    },
    {
        id: 3,
        title: 'Sự tận tâm, và tâm huyết bảo tồn bản sắc văn hóa',
        description:
            'Đó còn là tâm huyết trong việc bảo tồn văn hóa và thúc đẩy sự phát triển bền vững của cộng đồng địa phương. Không chỉ dừng lại trong khu vực, chúng tôi mong muốn lan tỏa những giá trị tốt đẹp của văn hóa Lô Lô Chải đến với bạn bè trong nước và quốc tế.'
    }
];
export const rooms1 = [
    {
        id: 1,
        img: rooms11,
    },
    {
        id: 2,
        img: rooms12,
    },
    {
        id: 3,
        img: rooms13,
    },
    {
        id: 4,
        img: rooms14,
    },
    {
        id: 5,
        img: rooms15,
    },
    {
        id: 6,
        img: rooms16,
    },
    {
        id: 7,
        img: rooms17,
    },
    {
        id: 8,
        img: rooms18,
    },
    {
        id: 9,
        img: rooms19,
    },
    {
        id: 10,
        img: rooms110,
    },
    {
        id: 11,
        img: rooms111,
    },
    {
        id: 12,
        img: rooms112,
    },
    {
        id: 13,
        img: rooms113,
    },
    {
        id: 14,
        img: rooms114,
    },
];
export const rooms2 = [
    {
        id: 1,
        img: rooms21,
    },
    {
        id: 2,
        img: rooms22,
    },
    {
        id: 3,
        img: rooms23,
    },
    {
        id: 4,
        img: rooms24,
    },
    {
        id: 5,
        img: rooms25,
    },
    {
        id: 6,
        img: rooms26,
    },
    {
        id: 7,
        img: rooms27,
    },
    {
        id: 8,
        img: rooms28,
    },
    {
        id: 9,
        img: rooms29,
    },
    {
        id: 10,
        img: rooms210,
    },
    {
        id: 11,
        img: rooms211,
    },
    {
        id: 12,
        img: rooms212,
    },
    {
        id: 13,
        img: rooms213,
    },
    {
        id: 14,
        img: rooms214,
    },
    {
        id: 15,
        img: rooms215,
    },
    {
        id: 16,
        img: rooms216,
    },
    {
        id: 17,
        img: rooms217,
    },
    {
        id: 18,
        img: rooms218,
    }
];
export const rooms3 = [
    {
        id: 1,
        img: rooms31,
    },
    {
        id: 2,
        img: rooms32,
    },
    {
        id: 3,
        img: rooms33,
    },
    {
        id: 4,
        img: rooms34,
    },
    {
        id: 5,
        img: rooms35,
    },
    {
        id: 6,
        img: rooms36,
    },
    {
        id: 7,
        img: rooms37,
    },
    {
        id: 8,
        img: rooms38,
    },
    {
        id: 9,
        img: rooms39,
    },
    {
        id: 10,
        img: rooms310,
    }
];
export const rooms4 = [
    {
        id: 1,
        img: rooms41,
    },
    {
        id: 2,
        img: rooms42,
    },
    {
        id: 3,
        img: rooms43,
    },
    {
        id: 4,
        img: rooms44,
    },
    {
        id: 5,
        img: rooms45,
    },
    {
        id: 6,
        img: rooms46,
    },
    {
        id: 7,
        img: rooms47,
    },
    {
        id: 8,
        img: rooms48,
    },
    {
        id: 9,
        img: rooms49,
    }
];
export const rooms5 = [
    {
        id: 1,
        img: rooms51,
    },
    {
        id: 2,
        img: rooms52,
    },
    {
        id: 3,
        img: rooms53,
    },
    {
        id: 4,
        img: rooms54,
    },
    {
        id: 5,
        img: rooms55,
    },
    {
        id: 6,
        img: rooms56,
    },
    {
        id: 7,
        img: rooms57,
    },
    {
        id: 8,
        img: rooms58,
    },
    {
        id: 9,
        img: rooms59,
    },
    {
        id: 10,
        img: rooms510,
    },
    {
        id: 11,
        img: rooms511,
    },
    {
        id: 12,
        img: rooms512,
    },
    {
        id: 13,
        img: rooms513,
    }
];

export const menu = [
    {
        id: 1,
        img: menu1,
        title: 'Americano Coffee',
        description: 'Milk with vanilla flavored',
        price: 50.25,
    },
    {
        id: 2,
        img: menu2,
        title: 'Cappuccino Arabica',
        description: 'Milk with vanilla flavored',
        price: 70.25,
    },
    {
        id: 3,
        img: menu3,
        title: 'Milk Cream Coffee',
        description: 'Milk with vanilla flavored',
        price: 30.25,
    },
    {
        id: 4,
        img: menu4,
        title: 'Special Raw Coffee',
        description: 'Milk with vanilla flavored',
        price: 80.25,
    },
    {
        id: 5,
        img: menu5,
        title: 'Fresh Black Coffee',
        description: 'Milk with vanilla flavored',
        price: 90.25,
    },
    {
        id: 6,
        img: menu6,
        title: 'Cappuccino Arabica',
        description: 'Milk with vanilla flavored',
        price: 70.25,
    },
    {
        id: 7,
        img: menu7,
        title: 'Milk Cream Coffee',
        description: 'Milk with vanilla flavored',
        price: 120.25,
    },
    {
        id: 8,
        img: menu8,
        title: 'Cold Coffee',
        description: 'Milk with vanilla flavored',
        price: 40.25,
    },
];

export const gallery = [
    {
        id: 1,
        img: gallery1,
        title: 'Cappuccino Arabica',
    },
    {
        id: 2,
        img: gallery2,
        title: 'Cappuccino Arabica',
    },
    {
        id: 3,
        img: gallery3,
        title: 'Cappuccino Arabica',
    },
    {
        id: 4,
        img: gallery4,
        title: 'Cappuccino Arabica',
    },
    {
        id: 5,
        img: gallery5,
        title: 'Cappuccino Arabica',
    },
];

export const offer = [
    {
        id: 1,
        img: offer1,
        title: 'Cappuccino Arabica',
        discount: 'Get 40% Offer',
        description:
            'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its normal distribution layout.',
    },
    {
        id: 2,
        img: offer2,
        title: 'Cappuccino Arabica',
        discount: 'Get 40% Offer',
        description:
            'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its normal distribution layout.',
    },
];

export const testimonials = [
    {
        id: 1,
        img: testimonial1,
        title: 'Reid E Butt',
        service: 'Customer',
        description:
            'There are many variations passages of the available but the majority have suffered to the alteration in some form by injected of humour or randomised words.',
    },
    {
        id: 2,
        img: testimonial2,
        title: 'Parker Jimenez',
        service: 'Customer',
        description:
            'There are many variations passages of the available but the majority have suffered to the alteration in some form by injected of humour or randomised words.',
    },
    {
        id: 3,
        img: testimonial3,
        title: 'Sylvia H Green',
        service: 'Customer',
        description:
            'There are many variations passages of the available but the majority have suffered to the alteration in some form by injected of humour or randomised words.',
    },
    {
        id: 4,
        img: testimonial4,
        title: 'Gordon D Novak',
        service: 'Customer',
        description:
            'There are many variations passages of the available but the majority have suffered to the alteration in some form by injected of humour or randomised words.',
    },
];
