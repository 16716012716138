import React from 'react';
import { Row, Col, Card } from 'antd';
import BacXiu from '../../assets/images/coffe/bacxiu'
import CoffeBodau from '../../assets/images/coffe/coffebodau'
import CoffeCam from '../../assets/images/coffe/coffecam'
import CoffeCotDua from '../../assets/images/coffe/coffecotdua'
import CoffeSuachua from '../../assets/images/coffe/coffesuachua'
import CoffeLatte from '../../assets/images/coffe/coffelatte'
import CoffeMenMen from '../../assets/images/coffe/coffemenmen'
import CoffeSua from '../../assets/images/coffe/coffesua'
import DenDa from '../../assets/images/coffe/denda'
import TraGungSa from '../../assets/images/tea/tragungxa'
import TraAtiso from '../../assets/images/tea/traatiso'
import TraCamQue from '../../assets/images/tea/tracamque'
import TraCamSa from '../../assets/images/tea/tracamsa'
import TraTaoBacHa from '../../assets/images/tea/trataobacha'
import TraDuaHau from '../../assets/images/tea/traduahau'
import TraTaoQue from '../../assets/images/tea/trataoque'
import TraCamGung from '../../assets/images/tea/tracamgung'
import TraHoaCuc from '../../assets/images/tea/trahoacuc'
import TraTaoDo from '../../assets/images/tea/trataodo'
import ChanhTuyet from '../../assets/images/ice/chanhtuyet'
import LatteDauBiec from '../../assets/images/ice/lattedaubiec'
import LiptonSua from '../../assets/images/ice/liptonsua'
import SinhTo from '../../assets/images/ice/sinhto'
import NuocEp from '../../assets/images/ice/nuocep'
import Matcha from '../../assets/images/ice/matcha'
import CacaoSua from '../../assets/images/ice/cacaosua'

const MenuCoffe = () => {
    const cafePhin = [
        { name: 'Bạc sỉu', image: <BacXiu /> },
        { name: 'Đen đá', image: <DenDa /> },
        { name: 'Cafe sữa', image: <CoffeSua /> },
        // { name: 'Cafe latte', image: <CoffeLatte /> },
        // { name: 'Cafe sữa chua', image: <CoffeSuachua /> },
        { name: 'Cafe cam', image: <CoffeCam /> },
        { name: 'Cafe cốt dừa', image: <CoffeCotDua /> },
        { name: 'Cafe bơ đậu', image: <CoffeBodau /> },
        { name: 'Cafe mèn mén', image: <CoffeMenMen /> }
    ];
    const cafeDigital = [
        { name: 'Espresso', image: <BacXiu /> },
        { name: 'Americano', image: <DenDa /> },
        { name: 'Latte', image: <CoffeSua /> },
        { name: 'Capuchino', image: <CoffeLatte /> },
    ];

    const traItems = [
        { name: 'Trà hoa cúc', image: <TraHoaCuc /> },
        { name: 'Trà cam gừng', image: <TraCamGung /> },
        { name: 'Trà cam quế', image: <TraCamQue /> },
        { name: 'Trà táo đỏ', image: <TraTaoDo /> },
                                { name: 'Trà dứa hấu', image: <TraDuaHau /> },
        // { name: 'Trà gừng/sả/chanh', image: <TraGungSa /> },
        // { name: 'Trà táo quế', image: <TraTaoQue /> },
        { name: 'Trà atiso cam', image: <TraAtiso /> },
        // { name: 'Trà cam sả', image: <TraCamSa /> },
        { name: 'Trà táo bạc hà', image: <TraTaoBacHa /> },
        
    ];
    const iceItems = [
        { name: 'Matcha', image: <Matcha /> },
        { name: 'Cacao sữa', image: <CacaoSua /> },
        { name: 'Nước ép', image: <NuocEp /> },
        { name: 'Sinh tố', image: <SinhTo /> },
        { name: 'Lipton sữa', image: <LiptonSua /> },
        { name: 'Chanh tuyết', image: <ChanhTuyet /> },
        // { name: 'Latte đậu biếc', image: <LatteDauBiec /> },
    ];
    // eslint-disable-next-line no-sparse-arrays
    const iceItemsLau = [
        {
            name: 'Lẩu gà đen 200k/người',
            name1: 'Gà đen tươi sống  .........................../đĩa',
            name2: 'Thịt bò tươi sống .........................../đĩa',
            name3: 'Bánh lắc khoải    ................................/đĩa',
            name4: 'Khoai lang, cà rốt ........................../đĩa',
            name5: 'Nấm, đậu phụ      ................................/đĩa',
            name6: 'Mì tôm      ............................................./gói',
            name7: 'Rau nhúng lẩu     .............................../đĩa',
            name8: 'Hoa quả tráng miệng ................../đĩa',
            name9: 'Rượu ngô Lô Lô    ............................/đĩa',
        },
        {
            name: 'Lẩu gà Lô Lô 150k/người',
            name1: 'Gà bản tươi sống  .........................../đĩa',
            name2: 'Thịt bò tươi sống .........................../đĩa',
            // name3: 'Bánh lắc khoải    .........................../đĩa',
            name4: 'Khoai lang, cà rốt ........................../đĩa',
            name5: 'Nấm, đậu phụ      ................................/đĩa',
            name6: 'Mì tôm            ............................................./gói',
            name7: 'Rau nhúng lẩu     .............................../đĩa',
            name8: 'Hoa quả tráng miệng ................../đĩa',
            name9: 'Rượu ngô Lô Lô    ............................/đĩa',
        },
        {
            name: 'Mẹt gà hấp/nướng 200k/người',
            name1: 'Mẹt gà thả dồi',
            name2: 'Lòng gà nấu miến',
            name3: 'Salad rau trộn',
            name4: 'Đậu phụ rán',
            name5: 'Rau thơm ăn kèm',
            name6: 'Rượu ngô Lô Lô',
            name7: 'Hoa quả tráng miệng',
            // name8: 'Hoa quả tráng miệng ........................./đĩa',
            // name9: 'Rượu ngô Lô Lô    .........................../đĩa',
        },
        {
            name: 'Lẩu cá 200k/người',
            name1: 'Cá thái lát tươi sống  ....................../đĩa',
            name2: 'Thịt lợn bản tươi sống ................../đĩa',
            // name3: 'Bánh lắc khoải    .........................../đĩa',
            name4: 'Khoai lang, cà rốt .........................../đĩa',
            name5: 'Nấm, đậu phụ      ................................./đĩa',
            name6: 'Mì tôm            ............................................../gói',
            name7: 'Rau nhúng lẩu     ................................/đĩa',
            name8: 'Hoa quả tráng miệng .................../đĩa',
            name9: 'Rượu ngô Lô Lô    ............................./đĩa',
        },
        {
            name: 'Đồ gọi thêm',
            name1: 'Gà đen tươi sống  ...................... 250k/đĩa',
            name2: 'Gà bản tươi sống ...................... 200k/đĩa',
            name3: 'Thịt bò tươi sống    ...................... 250k/đĩa',
            name4: 'Thịt lợn tươi sống ..................... 200k/đĩa',
            name5: 'Rau nhúng lẩu      ............................ 40k/đĩa',
            name6: 'Nấm, đậu phụ            ..............................40k/đĩa',
            // name7: 'Rau nhúng lẩu     .........................../đĩa',
            // name8: 'Hoa quả tráng miệng ........................./đĩa',
            // name9: 'Rượu ngô Lô Lô    .........................../đĩa',
        },
        {
            // name: 'Lẩu gà đen 200k/người',
            name1: 'Mì tôm              ........................................ 7k/gói',
            name2: 'Bánh lắc khoải      .......................... 50k/đĩa',
            name3: 'Rượu ngô Lô Lô      ....................... 50k/50ml',
            name4: 'Bia Hà Nội/ Sài Gòn/ 333 ...... 25k',
            name5: 'Coca Cola           ................................... 20k',
            name6: 'Nước lọc            ..................................... 10k',
            // name7: 'Rau nhúng lẩu     .........................../đĩa',
            // name8: 'Hoa quả tráng miệng ........................./đĩa',
            // name9: 'Rượu ngô Lô Lô    .........................../đĩa',
        },
    ];
    const iceItemsCom = [
        {
            name: 'Set cơm 150k/người (bàn từ 4 người)',
            name1: 'Gà nướng mắc kén  ................................../đĩa',
            name2: 'Bò xào hành tây/gừng .........................../đĩa',
            name3: 'Lợn bản nướng giềng/ hấp lá dong    .../đĩa',
            name4: 'Lòng gà xào rau bản ............................../đĩa',
            name5: 'Đậu phụ sốt cà chua      ............................../đĩa',
            name6: 'Rau bản theo mùa      ................................../gói',
            name7: 'Cơm, canh     ................................................/đĩa',
            name8: 'Hoa quả tráng miệng ............................/đĩa',
            // name9: 'Rượu ngô Lô Lô    ............................/đĩa',
        },
        {
            name: 'Set cơm 120k/người (bàn từ 6 người)',
            name1: 'Gà luộc/xào sả ớt  ............................../đĩa',
            name2: 'Lợn bản rang/nướng ......................./đĩa',
            // name3: 'Bánh lắc khoải    .........................../đĩa',
            name4: 'Trứng rán ............................................/đĩa',
            name5: 'Lòng gà xào rau bản      ......................../đĩa',
            name6: 'Đạu phụ sốt cà chua            ......................../gói',
            name7: 'Rau bản theo mùa     .........................../đĩa',
            name8: 'Cơm, canh ........................................../đĩa',
            name9: 'Hoa quả tráng miệng    ....................../đĩa',
        },
        // {
        //     name: 'Đồ gọi thêm',
        //     name1: 'Mẹt gà thả dồi',
        //     name2: 'Lòng gà nấu miến',
        //     name3: 'Salad rau trộn',
        //     name4: 'Đậu phụ rán',
        //     name5: 'Rau thơm ăn kèm',
        //     name6: 'Rượu ngô Lô Lô',
        //     name7: 'Hoa quả tráng miệng',
        //     // name8: 'Hoa quả tráng miệng ........................./đĩa',
        //     // name9: 'Rượu ngô Lô Lô    .........................../đĩa',
        // },
        // {
        //     name: 'Lẩu cá 200k/người',
        //     name1: 'Cá thái lát tươi sống  ....................../đĩa',
        //     name2: 'Thịt lợn bản tươi sống ................../đĩa',
        //     // name3: 'Bánh lắc khoải    .........................../đĩa',
        //     name4: 'Khoai lang, cà rốt .........................../đĩa',
        //     name5: 'Nấm, đậu phụ      ................................./đĩa',
        //     name6: 'Mì tôm            ............................................../gói',
        //     name7: 'Rau nhúng lẩu     ................................/đĩa',
        //     name8: 'Hoa quả tráng miệng .................../đĩa',
        //     name9: 'Rượu ngô Lô Lô    ............................./đĩa',
        // },
        {
            name: 'Đồ gọi thêm',
            name1: 'Gà nướng/luộc/rang  ................. 200k/đĩa',
            name2: 'Gà nướng/hấp/rán .................... 200k/đĩa',
            name3: 'Lợn nướng/hấp/rang    ................ 200k/đĩa',
            name4: 'Lạp xưởng .................................... 200k/đĩa',
            name5: 'Lợn treo gác bếp      ........................ 200k/đĩa',
            name6: 'Trứng rán            ...................................... 50k/đĩa',
            name7: 'Nem rán      ........................................ 60k/đĩa',
            name8: 'Khoai tây xào            ............................... 50k/đĩa',
            name9: 'Rau bản theo mùa      ...................... 40k/đĩa',
            name10: 'Cơm      ................................................ 30k/đĩa',
            name11: 'Canh            ............................................... 30k/đĩa',
            name12: 'Măng xào            ...................................... 40k/đĩa',
            // name7: 'Rau nhúng lẩu     .........................../đĩa',
            // name8: 'Hoa quả tráng miệng ........................./đĩa',
            // name9: 'Rượu ngô Lô Lô    .........................../đĩa',
        },
        {
            name: 'Đồ uống ',
            name1: 'Rượu ngô Lô Lô      ......................... 50k/50ml',
            name2: 'Bia Hà Nội/ Sài Gòn/ 333 .................. 25k',
            name3: 'Coca Cola           .............................................. 20k',
            name4: 'Trà xanh không độ .............................. 20k',
            name5: 'Trà đào           ................................................... 20k',
            name6: 'Red bull           .................................................. 25k',
            name7: 'Nước lọc            ................................................. 10k',
            // name7: 'Rau nhúng lẩu     .........................../đĩa',
            // name8: 'Hoa quả tráng miệng ........................./đĩa',
            // name9: 'Rượu ngô Lô Lô    .........................../đĩa',
        },
    ];

    return (
        <div className='mt-[100px] md:mx-40 mx-3'>
            <div className="flex items-center justify-center mb-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Cafe Phin - Vietnamese Drip Coffee</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]}>
                {cafePhin.map((item, index) => (
                    <Col key={index} xs={12} sm={8} md={6} lg={6}>
                        <Card
                            // hoverable
                            cover={typeof item.image === 'string' ? (
                                <img alt={item.name} src={item.image} style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', backgroundColor: '#864622', marginBottom: '0' }} />
                            ) : (
                                React.cloneElement(item.image, { style: { width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', marginBottom: '0' } })
                            )}
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={<div className='text-[#864622]' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
            <div className="flex items-center justify-center mb-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Cafe máy - Brewed Coffee</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]}>
                {cafeDigital.map((item, index) => (
                    <Col key={index} xs={12} sm={8} md={6} lg={6}>
                        <Card
                            // hoverable
                            // cover={typeof item.image === 'string' ? (
                            //     <img alt={item.name} src={item.image} style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', backgroundColor: '#864622', marginBottom: '0' }} />
                            // ) : (
                            //     React.cloneElement(item.image, { style: { width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', marginBottom: '0' } })
                            // )}
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={<div className='text-[#864622]' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>

            <div className="flex items-center justify-center mb-8 mt-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Trà - Tea</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]}>
                {traItems.map((item, index) => (
                    <Col key={index} xs={12} sm={8} md={6} lg={6}>
                        <Card
                            // hoverable
                            cover={typeof item.image === 'string' ? (
                                <img alt={item.name} src={item.image} style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', backgroundColor: '#864622', marginBottom: '0' }} />
                            ) : (
                                React.cloneElement(item.image, { style: { width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', marginBottom: '0' } })
                            )}
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={<div className='text-[#864622]' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
            <div className="flex items-center justify-center mb-8 mt-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Đồ Lạnh - Cold Drinks</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]} className='mb-20'>
                {iceItems.map((item, index) => (
                    <Col key={index} xs={12} sm={8} md={6} lg={6}>
                        <Card
                            // hoverable
                            cover={typeof item.image === 'string' ? (
                                <img alt={item.name} src={item.image} style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', backgroundColor: '#864622', marginBottom: '0' }} />
                            ) : (
                                React.cloneElement(item.image, { style: { width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'cover', marginBottom: '0' } })
                            )}
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={<div className='text-[#864622]' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
            <div className="flex items-center justify-center mb-8 mt-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Menu lẩu</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]} className='mb-20'>
                {iceItemsLau.map((item, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={12}>
                        <Card
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={
                                    <div className="ml-8 md:ml-16 lg:md-40">
                                        <div className='text-[#864622] text-left text-xl font-bold mb-4' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name1}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name2}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name3}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name4}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name5}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name6}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name7}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name8}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name9}</div>
                                    </div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
            <div className="flex items-center justify-center mb-8 mt-8">
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
                <h2 className="mx-4 text-2xl font-bold text-[#864622]"
                    style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Set cơm</h2>
                <div className="flex-grow border-t border-[#864622] border-[2px]"></div>
            </div>
            <Row gutter={[16, 16]} className='mb-20'>
                {iceItemsCom.map((item, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={12}>
                        <Card
                            style={{ textAlign: 'center', border: 'none', backgroundColor: 'transparent', padding: '0' }}
                        >
                            <Card.Meta
                                title={
                                    <div className="ml-8 md:ml-16 lg:md-40">
                                        <div className='text-[#864622] text-left text-xl font-bold mb-4' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name1}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name2}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name3}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name4}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name5}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name6}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name7}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name8}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name9}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name10}</div>
                                        <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name11}</div>
                                                                                <div className='text-[#864622] text-left' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.name12}</div>
                                    </div>}
                                style={{ color: '#864622' }}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default MenuCoffe;
